var isOk = false;
var $clickedElement;

$(function() {
    /**
     * Activate tooltips when page loaded
     */
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
        trigger: 'hover'
    });

    /**
     * Enable helper for animateCss
     * Animate.css - jQuery extension
     */
    $.fn.extend({
        animateCss: function (animationName, callback) {
            var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            this.addClass('animated ' + animationName).one(animationEnd, function() {
                $(this).removeClass('animated ' + animationName);
                if(callback) callback();
            });
        }
    });

    /**
     * Activate confirmation dialog on action
     */
    $( document ).on( "click", ".must-confirm-dialog", function(e) {
        $clickedElement = $(this);

        if(isOk) return true;

        $.confirm({
            title: Translator.trans("message.confirm.action.title"),
            content: Translator.trans("message.confirm.action.content"),
            buttons: {
                cancel: {
                    text: Translator.trans("form.action.cancel"),
                    action: function () {
                        isOk = false;
                    }
                },
                confirm: {
                    text: Translator.trans("form.action.confirm"),
                    btnClass: 'btn-red',
                    action: function () {
                        isOk = true;
                        if($clickedElement[0].tagName == "A") {
                            window.location = $clickedElement.attr("href");
                        } else {
                            $clickedElement.trigger("click");
                        }
                    }
                },
            }
        });
        return false;
    });

    /**
     * Auto-enable button when page is loaded
     */
    $(".auto-enable").prop("disabled",false);

    /**
     * Warn not complete profile
     */
    $(".warn-profile-to-complete").confirm({
        title: Translator.trans("profile.notcomplete.title"),
        content: Translator.trans("profile.notcomplete.content") 
            + '<br /><a href="'+ Routing.generate('profile_edit') +'">' 
            + Translator.trans("profile.notcomplete.linkText")
            + '</a>'
    });

    /**
     * Activate confirmation dialog on account deletion
     */
    var delete_step_one = 0;
    $( document ).on( "click", ".confirm-account-deletion", function(e) {
        if(delete_step_one < 1)
        {
            delete_step_one = 1;
            $(this)[0].innerHTML = "Cliquer à nouveau pour supprimer votre compte";
            $(this)[0].classList.add('btn-danger');
        }
        else
        {
            $.confirm({
                title: Translator.trans("message.confirm.delete_profile.title"),
                content: Translator.trans("message.confirm.delete_profile.content"),
                buttons: {
                    cancel: {
                        text: Translator.trans("form.action.cancel"),
                        action: function () {
                            isOk = false;
                        }
                    },
                    confirm: {
                        text: Translator.trans("form.action.confirm"),
                        btnClass: 'btn-red',
                        action: function () {
                            isOk = true;
                            window.location = Routing.generate('profile_delete');
                        }
                    },
                }
            });
            return false;
        }
    });
});
