
global.ActivityTrack = function()
{
    this.updatetickroute =  "";
    this.mouseovertrackroute = "";
    this.pricechangeroute = "";
    this.newfurnitureadded = "";
    const ACTIVITY_DELAY = 60*1000; //ms
    const ID_TOTRACK = ["#action-order-link"];
    const MAX_PRICE_CHANGED = 5;
    this.activity = false;
    this.last_price = -500000;
    this.projectSlug = "";
    var _self = this;

    this.trackpricechange = [];

    this.price_change_purge = function()
    {
        if(_self.trackpricechange.length > 0)
        {
            $.post(_self.pricechangeroute, {listprice:_self.trackpricechange}, function(data) {});
            _self.trackpricechange = []
        }
    }

    this.tick = function()
    {
        if(_self.activity)
        {
            $.post(_self.updatetickroute, 0, function(data) {});
            _self.activity = false;
            _self.trackMouse()
        }

        _self.price_change_purge();
    }

    $(document).on("price_changed", function(event,price,mode)
    {
        if(price != _self.last_price)
        {
            _self.trackpricechange.push(price);
            _self.last_price = price;
        }
        if(_self.trackpricechange.length >= MAX_PRICE_CHANGED)
        {
            _self.price_change_purge();
        }
    })

    $(document).on("new_furniture_added", function(event,type)
    {
        var tabtype = ["Placard","Etagère","Caisson","Panneau"];
        $.post(_self.newfurnitureadded,{furniture_type:tabtype[type]},function(data) {})
    })

    $( window ).on( "load", function() {
        _self.updatetickroute = Routing.generate('updatetick');
        _self.mouseovertrackroute = Routing.generate('updatemouseover');
        _self.projectSlug = $("#project-slug").val();
        _self.pricechangeroute = Routing.generate('pricechangedtrack',{'slug': _self.projectSlug});
        _self.newfurnitureadded = Routing.generate('newfurnitureadded',{'slug': _self.projectSlug});
        $.post(_self.updatetickroute, 0, function(data) {});
    })

    this.trackMouse = function()
    {
        $( document ).on( "mousemove", function() {
            _self.activity = true;
            _self.untrackMouse();
        })
    }
    this.trackMouse();

    this.untrackMouse = function()
    {
        $( document ).off( "mousemove");
    }

    setInterval(this.tick, ACTIVITY_DELAY);

    for(i = 0; i< ID_TOTRACK.length; i++)
    {
        $(ID_TOTRACK[i]).on("mouseover",{id:ID_TOTRACK[i]}, function(event)
        {
            $.post(_self.mouseovertrackroute, {id:event.data.id}, function(data) {});
        })
    }

}

_AT = new ActivityTrack();
