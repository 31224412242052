var quoteChange = true;

$(document).ready(function(){
    enableQuoteManagers();
});

global.enableQuoteManagers = function()
{
    setTimeout(function() {
        var num = $(".btn-quote").length;
        $(".btn-quote").each(function() {
            this.quote_manager = new QuoteManager(this,num==1);
        });
        
        $(".btn-quote-pro").each(function() {
            this.quote_manager = new QuoteManager(this,1, true);
        });
        
        if($(".quoteto-address").val()) {
            $(".quoteto-address").trigger("change");
        }
    }, 500);
}

global.QuoteManager = function(btn,isAlone, pro=false)
{
    this.btn = btn;
    this.pro = pro;
    this.slug = $(btn).attr('href').split('/').slice(-2)[0];
    this.delay_time_quote_check = 1000*2;
    this.QuoteIsReady = false;
    this.QuoteIsGenerating = false;
    this.QuoteRequestMaxNumber = 50;
    this.QuoteRequestNumber = this.QuoteRequestMaxNumber;
    this.isAlone = isAlone;

    $(this.btn).on('click',function (){
        var qm = $(this)[0].quote_manager;
        if(quoteChange)
        {
            qm.QuoteIsReady = false;
            qm.startGenerating(qm, true);
            quoteChange = false;
        }
        else
        {
            if(qm.QuoteIsReady) {
                qm.give_quote();
                quoteChange = true;
                return true;
            } else {
                qm.startGenerating(qm, false)
                return false;
            }
        }
        return false;
    });

    this.startGenerating = function(qm, force)
    {
        $.getJSON(Routing.generate('document_project_has_quote',{'slug': qm.slug, 'pro': qm.pro}),{},
        function(data) {
            if(force == false && data.hasquote){
                qm.give_quote();
                qm.QuoteIsReady = true;
            }else {
                if(!qm.QuoteIsGenerating && data.hasplans){
                    qm.generate_quote(qm.pro);
                    qm.QuoteIsReady = false;
                }
                else
                {
                    $.confirm({
                        title: Translator.trans('message.plans.not_ready.title'),
                        content: Translator.trans('message.plans.not_ready.content'),
                        buttons: {
                                close: function () {
                            }
                        }
                    });
                }
            }
        }
    );
    }

    this.is_quote_document_ready =function (self)
    {
        $.getJSON(Routing.generate('document_project_has_quote',{'slug': self.slug, 'pro': self.pro}), {},
            function(data) {
                if(data.hasquote){
                    self.QuoteIsReady = true;
                    self.QuoteIsGenerating = false;
                    self.give_quote()
                }
                else if(self.QuoteRequestNumber>0){
                    setTimeout(self.is_quote_document_ready, self.delay_time_quote_check,self);
                    self.QuoteRequestNumber--;
                }
                else {
                    self.reset_quote()
                }
            }
        );
    }

    this.give_quote = function()
    {
        if(this.isAlone)
            $(this.btn).html('<i class="fa fa-download" aria-hidden="true"></i> '+ Translator.trans('form.download.quote'));
        else
            $(this.btn).html('<i class="fa fa-download" aria-hidden="true"></i>');
        this.QuoteIsReady = true;
    }

    this.generate_quote = function(pro, force=true)
    {
        this.QuoteIsGenerating = true;
        if(this.isAlone)
            $(this.btn).html('<i class="fa fa-spinner fa-spin"></i> '+ Translator.trans('form.generating.quote'));
        else
            $(this.btn).html('<i class="fa fa-spinner fa-spin"></i>');
        if(pro)
            $.getJSON(Routing.generate('document_project_generate_quote',{'slug': this.slug, 'force': force, 'pro': pro, 'address': $(".quoteto-address").val(), 'pricemode': $('input[type=radio][name=price_mode]:checked')[0].id, 'vatmode': $('input[type=radio][name=vat_mode]:checked')[0].id, 'showdiscount': $(".show-discount")[0].checked, 'overtax': $(".price_overtax").val()  }),{}, function(data){
                $("#summary_command").html(data.summary);
            });
        else
            $.getJSON(Routing.generate('document_project_generate_quote',{'slug': this.slug}),{}, function(data){
                // $("#summary_command").html(data.summary);
            });
        setTimeout(this.is_quote_document_ready, this.delay_time_quote_check, this);
    }

    this.reset_quote = function()
    {
        this.QuoteIsGenerating = false;
        if(this.isAlone)
            $(this.btn).html('<i class="fa fa-print" aria-hidden="true"></i> ' + Translator.trans('form.action.generate.quote'));
        else
            $(this.btn).html('<i class="fa fa-print" aria-hidden="true"></i>');
        this.QuoteIsReady = false;
        this.QuoteRequestNumber = this.QuoteRequestMaxNumber;
    }
}

$(".quoteto-address").change(function() {
    quoteChange = true;
    $(".billing-address").prop("disabled", true);
    $(".billing-loading").removeClass("hidden");
    $(".no-billing-address").hide();
    $(".order-validate").prop("disabled",true);
    $.getJSON(Routing.generate('profile_get_address',{'address': $(this).val()}), 0, function(data) {
//     $.getJSON(getAddressUrl.replace('__id__', $(this).val()), 0, function(data) {
        $(".chosen-billing-address").html(data.address_template);
        $(".chosen-billing-address").animateCss("fadeIn");
        $(".billing-address").prop("disabled", false);
        $(".billing-loading").addClass("hidden");
        $(".order-validate").prop("disabled",false);
    });
});

$(".price_overtax_eur").keyup(function() {
    var pc = Math.round(100*100*($(".price_overtax_eur").data("init-price") + parseInt($(".price_overtax_eur").val()))/$(".price_overtax_eur").data("init-price")-100*100)/100;
    $(".price_overtax").val(pc);
});

$('input[type=radio][name=price_mode]').change(function() {
    quoteChange = true;
});

$('input[type=radio][name=vat_mode]').change(function() {
    quoteChange = true;
});

$(".show-discount").change(function() {
    quoteChange = true;
});

$(".price_overtax").change(function() {
    quoteChange = true;
});

global.delete_service = function(itemId) {
    $.confirm({
        title: 'Supprimer ce service',
        content: 'Êtes-vous sûr de vouloir supprimer ce service ?',
        typeAnimated: true,
        buttons: {
            Annuler: function() {},
            formSubmit: {
                text: 'Supprimer',
                btnClass: 'btn-red',
                action: function() {
                    quoteChange = true;
                    route = Routing.generate("billitem_remove", {'item': itemId});
                    $.get(route, 0, function(data) {
                        quoteChange = true;
                        $("#summary_command").html(data);
                    });
                }
            },
        }
    });
}

global.edit_service = function(itemId) {
    $.get(Routing.generate("bill_get_billItem_form", {
        'slug': $("#project-slug").val(),
        'billItem': itemId
    }), 0, function(form) {
        $.confirm({
            title: 'Modifier un service',
            content: form,
            typeAnimated: true,
            columnClass: 'large',
            buttons: {
                fermer: function() {},
                formSubmit: {
                    text: 'Modifier',
                    btnClass: 'btn-blue',
                    action: function() {
                        quoteChange = true;
                        $.post(Routing.generate("bill_get_billItem_form", {
                            'slug': $("#project-slug").val(),
                            'billItem': itemId
                        }), this.$content.find("form").serialize(), function(data) {
                            $("#summary_command").html(data);
                        });
                    }
                },
            }
        });
    });
}

global.apply_promo = function() {
    $.post("/api/promocode/" + $("#project-slug").val() + "/" + $("#promo-code").val(),
    {}, function(data) {
        if (data.success) {
            $("#promo-code").prop('value', '');
            location.reload();
        } else {
            $("#promo-code-error")[0].innerHTML = data.data.errors.message + "<br /><br />";
        }
    });
}

global.removePromoCode = function(code) {
    $.ajax({
        url: "/api/promocode/" + $("#project-slug").val() + "/" + code,
        type: 'DELETE',
        success: function(data) {
            if (data.success) {
                location.reload();
            }
        }
    });
}

global.add_service = function() {
    $.get(Routing.generate("bill_get_billItem_form", {
        'slug': $("#project-slug").val()
    }), 0, function(form) {
        $.confirm({
            title: 'Ajouter un service',
            content: form,
            typeAnimated: true,
            columnClass: 'large',
            buttons: {
                fermer: function() {},
                formSubmit: {
                    text: 'Ajouter',
                    btnClass: 'btn-blue',
                    action: function() {
                        quoteChange = true;
                        $.post(Routing.generate("bill_get_billItem_form", {
                            'slug': $("#project-slug").val()
                        }), this.$content.find("form").serialize(), function(data) {
                            $("#summary_command").html(data);
                        });
                    }
                },
            }
        });
    });
}
