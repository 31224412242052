$(document).on('click','.spinner .btn:first-of-type', function(e) {
    let $input = $(this).parents(".spinner").find('input');
    let setToValue = parseInt($input.val(), 10) + 1;
    if(setToValue > $(this).parents(".spinner").data("max")) {
        return;
    }
    $input.val(setToValue);
    $input.trigger("change");
    e.stopPropagation();
});
$(document).on('click','.spinner .btn:last-of-type', function(e) {
    let $input = $(this).parents(".spinner").find('input');
    let setToValue = parseInt($input.val(), 10) - 1;
    if(setToValue < $(this).parents(".spinner").data("min")) {
        return;
    }
    $input.val(setToValue);
    $input.trigger("change");
    e.stopPropagation();
});
